<template>
    <div>
        <div class="position-relative">
            <div class="position-absolute" style="left: 5px; top: 9px;">
                <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0 d-flex align-items-center" title="Actualizar" v-on:click="getData()" tabindex="-1" :disabled="loadingPaises">
                    <font-awesome-icon :icon="faSyncAlt" />
                </button>
            </div>
            <select class="form-select" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" style="padding-left: 42px;" :disabled="loadingPaises || !hiddeElement || disabledDF" :required="requiredDF" :id="idDF">
                <option value="" disabled selected>
                    <span v-if="loadingPaises">Cargando...</span>
                    <span v-else><span v-if="requiredDF">*</span>Seleccionar país</span>
                </option>
                <option :value="pais.CODIGO_PAIS" v-text="pais.NOMBRE_PAIS" v-for="(pais, index) in paises" :key="index"></option>
            </select>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
export default {
    name: 'catalogo-paises',
    props: ['modelValue', 'disabled', 'required', 'id'],
    emits: ['update:modelValue'],
    components: {
        FontAwesomeIcon
    },
    data(){
        return {
            faSyncAlt,
            loadingPaises: false,
            hiddeElement: true,
            country: {}
        }
    },
    mounted(){
        if(this.paises.length<=0){
            this.getData()
        }else{
            if(this.paises.length==1){
                this.hiddeElement = false
                this.$emit('update:modelValue', this.paises[0].CODIGO_PAIS)
            }else{
                this.hiddeElement = true
            }
        }

        this.getCountryDefault()
    },
    methods: {
        getData(){
            this.loadingPaises = true
            this.$store.dispatch('getPaises', {
                pagination: 0
            }).then(res => {
                if(res.data.length==1){
                    this.hiddeElement = false
                    this.$emit('update:modelValue', res.data[0].CODIGO_PAIS)
                }else{
                    this.hiddeElement = true
                }
                this.setCountry()
                return res
            }).catch(err => {
                return err
            }).then(() => {
                this.loadingPaises = false
            })
        },
        getCountryDefault(){
            this.$store.dispatch('getCountry').then((res) => {
                this.country = res.data || null
            })
        },
        setCountry(){
            if (!this.modelValue) {
                if (this.paises.length>1 && this.country) {
                    var dd = this.paises.filter(x => x.ISO == this.country.country)
                    if (dd.length>0) {
                        this.$emit('update:modelValue', dd[0].CODIGO_PAIS)
                    }
                }
            }
        }
    },
    computed: {
        paises() {
            var obj = this.$store.getters.paises
            if (obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
                return []
            }else{
                return obj || []
            }
        },
        disabledDF(){
            return this.disabled
        },
        requiredDF(){
            return this.required
        },
        idDF(){
            return this.id
        },
        loading() {
            return this.$store.getters.loading
        }
    },
    watch: {
       'country':{
            handler(val){
                this.setCountry()
                return val
            },
            deep: true
        } 
    }
}
</script>